import React, { useState } from "react";
// used for making the prop types of this component
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import PropTypes from "prop-types";
// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.png";
import defaultAvatar from "assets/img/placeholder.jpg";

export default function ImageUpload(props) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  let fileInput = React.createRef();

  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setFileName(file.name);

      setImagePreviewUrl(reader.result);
      if (props.onChange) {
        props.onChange(file);
      }
    };
    reader.readAsDataURL(file);
  };


  const handleSubmit = async (e) => {
    console.log(e);
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('id_std', sessionStorage.getItem("id_std"));
    formData.append('position', e);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/MyuploadPicture.php?`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(res => {
          setFile(null);//hide buttons
          return;
        })
    } catch (ex) {
      console.log(ex);
    }
  };
  // eslint-disable-next-line
  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps, uploadButtonProps, id } = props;

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              Change
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> Remove
            </Button>
            <Button {...uploadButtonProps} onClick={() => handleSubmit(id)}>
              {/* <i className="fas fa-times" />  */}
              Upload
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  uploadButtonProps: PropTypes.object,
  // it is a function from which you can get the files and fileNames that were uploaded
  // more can be read here: https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  onChange: PropTypes.func
};
