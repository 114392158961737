/*eslint-disable*/
import React, { useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";


//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//import InputAdornment from "@material-ui/core/InputAdornment";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
//import Icon from "@material-ui/core/Icon";
//import RoomIcon from '@material-ui/icons/Room';

import SectionSlider from "views/PresentationPage/Sections/SectionSlider.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Search from "@material-ui/icons/Search";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

// @material-ui/icons
//import Email from "@material-ui/icons/Email";
// import Favorite from "@material-ui/icons/Favorite";
//import Face from "@material-ui/icons/Face";
// core components
import Parallax from "components/Parallax/Parallax.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import { TextField } from '@material-ui/core/';

import formPageStyle from "assets/jss/material-kit-pro-react/views/presentationSections/formPageStyle.js";

const useStyles = makeStyles(formPageStyle);

export default function LoginPage() {
  const [checkedA, setCheckedA] = React.useState(true);
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //    return function cleanup() {};
  // });
  const history = useHistory();
  
  const classes = useStyles();

  const [placeInfo, setplaceInfo] = useState({
    city_map: '',
    //pass: '',
  });
  const [message, noResultCityMap] = useState([]);
 
  const onChangeValue = (e) => {
    setplaceInfo({
      ...placeInfo,
      [e.target.name]: e.target.value
    });
  }

  const getResearch = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/GetResearch.php`, {
         city_map: placeInfo.whereParameter,
        // username: userInfo.name,
        // useremail: userInfo.email,
        // userpass: userInfo.pass,
      })
        .then(res => {         
          if(res.data.success===true){
            //const latitude_map = res.data.latitude_map;
            sessionStorage.setItem("latitude_map", res.data.latitude_map);
            //onst longitude_map = res.data.longitude_map;
            sessionStorage.setItem("longitude_map", res.data.longitude_map);
            sessionStorage.setItem("city_map", res.data.city_map);
            history.push("/searchMaps-page");
          }
           else{
            //history.push("/");
            //console.log(res.data.noresultcitymap);
            noResultCityMap('no result, please try with another city');
            return;
           }
           
        
        })
    } catch (error) { throw error; }
  };

  return (
    <div>
      {/* <Parallax
        // image={require("assets/img/background-form.jpg")}

        image={require("assets/img/preview.jpg")}
        className={classes.parallax}
      ></Parallax> */}
        
      <ScrollAnimation animateIn="animate__fadeInUp">
      <div id="FormSearch" className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>          
              <Card>
              <form className={classes.form} onSubmit={getResearch}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Search</h4> 
                  </CardHeader>

                  <CardBody signup>
                  <TextField className={classes.input}
                      type="text"
                      id="_whereParameter"
                      name="whereParameter"
                      variant="standard"
                      onChange={onChangeValue}
                      label="City required..."
                      placeholder="Where would you like to go?"
                      autoComplete="off"
                      required
                    />
              
            
                  <h6>{message}</h6>  
                  {/* <CustomInput
                          id="whereParameter"
                          name="whereParameter"
                          onChange={onChangeValue}
                          formControlProps={{
                            fullWidth: true,
                            // className: classes.cardForm
                          }}
                          inputProps={{
                            placeholder: "Where...",
                          type: "text",
                          startAdornment: (
                            <InputAdornment position="start">
                               <RoomIcon />
                            </InputAdornment>
                          )                            
                          }}
                        />          */}
                  </CardBody>
                  {/* <SectionSlider /> */}
                  {/* <div className={classes.container}>

                  <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(22)}
                      checked={checked.indexOf(22) !== -1 ? true : false}

                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label, root: classes.labelRoot }}
                  label="Villa"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(21)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label, root: classes.labelRoot }}
                  label="House"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(21)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label, root: classes.labelRoot }}
                  label="Appartment"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(21)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label, root: classes.labelRoot }}
                  label="Bungalow"
                />
              </div>
              <br />
               <FormControlLabel
                  control={
                    <Switch
                      checked={checkedA}
                      onChange={event => setCheckedA(event.target.checked)}
                      value="checkedA"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Furnished / Unfurnished"
                />
              </div> */}
              <br /><br />

                  <div className={classes.textCenter}>
                  <Button href="/" color="info" round onClick={getResearch}>Get started&nbsp;&nbsp;
                 <Search className={classes.icons} />
              </Button>
              <br /><br />
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        </ScrollAnimation>
      </div>
  );
}
