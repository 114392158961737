const contactsSection = {
  map: {
    overflow: "hidden",
    width: "100%",
    height: "500px",
    position: "absolute"
  },
  contenerMap: {
    padding: "0",
    height: "500px"
  }
};

export default contactsSection;
