import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Footer from "components/Footer/Footer.js";

import conectPageStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";
 
const useStyles = makeStyles(conectPageStyle);

export default function SectionFooter() {
    const classes = useStyles();

    return (    
      <div>
<Footer
className={classes.footer}
content={
  <div>
    <div className={classes.left}>
      <List className={classes.list}>
        <ListItem className={classes.inlineBlock}>
          <a
            href="/"
            className={classes.block}
          >
            MYHOUSEOO     
          </a>
        </ListItem>
        <ListItem className={classes.inlineBlock}>
          <a
            href="http://localhost/MyHouseOO/MENTIONS_LEGALES_MYHOUSEOO.pdf"
            target="_blank"
            without rel="noopener noreferrer"
            className={classes.block}
          >
            LEGALE
          </a>
        </ListItem>
        <ListItem className={classes.inlineBlock}>
          <a
            href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
            target="_blank"
            without rel="noopener noreferrer"
            className={classes.block}
          >
            CGU
          </a>
        </ListItem>
      </List>
    </div>
    <div className={classes.right}>
      Copyright 
      &copy; {1900 + new Date().getYear()} , {" "}
      <a
        href="https://www.artwebprod.com"
        target="_blank"
        without rel="noopener noreferrer"
      >
        ARTWEB-PRODUCTION
      </a>{" "}
     
    </div>
  </div>
}
/>
</div>
  );
}
