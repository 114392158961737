/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { DateRange } from 'react-date-range';
import format from 'date-fns/format';
import { addDays } from 'date-fns';

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export default function DateRangeComp() {

  //date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);


  //open close
  const [open, setOpen] = useState(false);//ou false pour le masquer
  const refOne = useRef(null);

  useEffect(() => {
    //set  current date on component Load
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, [])

  //hide dropdown on Esc press
  const hideOnEscape = (e) => {
    //console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false)
    }
  }

  const hideOnClickOutside = (e) => {
    //console.log(refOne.current)
    //console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false)
    }
  }

  return (
    <div className="calendarWrap">
      {/* les values sont valeurs a collectées et affiché */}
      {sessionStorage.setItem("startDate",`${format(range[0].startDate, "MM/dd/yyyy")}`)}
      {sessionStorage.setItem("endDate", `${format(range[0].endDate, "MM/dd/yyyy")}`)}
      <input
 value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
 readOnly
 className="inputBox"
 onClick={ () => setOpen(open => !open) }
 />
      <div ref={refOne}>
        {open &&
          <DateRange
            onChange={item => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="vertical"
            className="calendarElement"
          />
        }
      </div>
    </div>
  );
}



