/*eslint-disable*/
import React from "react";
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styled from 'styled-components';

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";

import{ init } from "_config/ApiConfig";

// sections for this page
import SectionFormPage from "views/PresentationPage/Sections/SectionFormPage.js";

// import SectionMapsPage from "views/MapsPage/Sections/SectionMapsPage.js";
// import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
// import SectionComponents from "views/PresentationPage/Sections/SectionComponents.js";
// import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
// import SectionContent from "views/PresentationPage/Sections/SectionContent.js";
// import SectionSections from "views/PresentationPage/Sections/SectionSections.js";
// import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
// import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
// import SectionOverview from "views/PresentationPage/Sections/SectionOverview.js";
// import SectionPricing from "views/PresentationPage/Sections/SectionPricing.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

init();

const useStyles = makeStyles(presentationStyle);

  const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, 1350, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
   

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      {/* <div style={{
        backgroundImage:`url(${ApiConfig.baseUrl}images/background-intro.jpg)`,
      }}
      /> */}
      <Header
      //  brand="MyHouseOO"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <Parallax
      // image={require("assets/img/suite1.jpeg")}aretoucher3.jpg
      image={require("assets/img/aretoucher3.jpg")}

     
        // image={require("assets/img/background-intro.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.shadowForText}>
                  MyHouseOO
                </h1>
                <h2 className={classNames(classes.title,classes.shadowForText)}>
                Sell, rent with trust!</h2>
                <h3 className={classNames(classes.title,classes.shadowForSmallText)}>Start to search now</h3>
                <Link to={""}>
<Button
color="info"
endIcon={<ArrowForwardIconAnimated />}
className={classNames(classes.navButton,classes.shadowForButton)}
round
onClick={e => smoothScroll(e, "FormSearch")}
>
  Start here
  </Button>
</Link>

              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
      {/* <div className={classes.space50} /> */}
      
     <SectionFormPage /> 
     {/* <SectionMapsPage /> */}
     <div id="FormSearch"></div> 
    
     
       {/* <SectionDescription />
        <SectionComponents /> 
         <SectionCards />
        <SectionContent />        
        <SectionSections />
        <SectionExamples />
        <SectionFreeDemo />
        <SectionOverview /> */}
      {/* </div> */}
      {/* <SectionPricing /> */}
      <Footer
      theme="white"
className={classes.footer}
content={
  <div>
    <div className={classes.left}>
      <List className={classes.list}>
        <ListItem className={classes.inlineBlock}>
          <a
            href="/"
            className={classes.block}
          >
            MYHOUSEOO     
          </a>
        </ListItem>
        <ListItem className={classes.inlineBlock}>
          <a
            href="http://localhost/MyHouseOO/MENTIONS_LEGALES_MYHOUSEOO.pdf"
            target="_blank"
            without rel="noopener noreferrer"
            className={classes.block}
          >
            LEGALE
          </a>
        </ListItem>
        <ListItem className={classes.inlineBlock}>
          <a
            href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
            target="_blank"
            without rel="noopener noreferrer"
            className={classes.block}
          >
            CGU
          </a>
        </ListItem>
      </List>
    </div>
    <div className={classes.right}>
      Copyright 
      &copy; {1900 + new Date().getYear()} , {" "}
      <a
        href="https://www.artwebprod.com"
        target="_blank"
        without rel="noopener noreferrer"
      >
        ARTWEB-PRODUCTION
      </a>{" "}
     
    </div>
  </div>
}
/>


      {/* <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-presentation"
                target="_blank"
                className={classes.footerBrand}
              >
                Material Kit PRO React
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/?ref=mkpr-presentation"
                    target="_blank"
                    className={classes.block}
                  >
                    Creative Tim
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-presentation"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="//blog.creative-tim.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-presentation"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://twitter.com/CreativeTim?ref=creativetim"
                    target="_blank"
                    color="twitter"
                    justIcon
                    simple
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://dribbble.com/creativetim?ref=creativetim"
                    target="_blank"
                    color="dribbble"
                    justIcon
                    simple
                  >
                    <i className="fab fa-dribbble" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://instagram.com/CreativeTimOfficial?ref=creativetim"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      /> */}
    </div>
  );
}
