/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Email from "@material-ui/icons/Email";
// import Favorite from "@material-ui/icons/Favorite";
// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core/';

import conectPageStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";

import image from "assets/img/background-intro.jpg";

const useStyles = makeStyles(conectPageStyle);

export default function LoginPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const history = useHistory();
  const [userInfo, setuserInfo] = useState({
    email: '',
    pass: '',
  });

  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }

  const submitUser = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/CheckUser.php`, {
        useremail: userInfo.email,
        userpass: userInfo.pass,
      })
        .then(res => {
          console.log(res);
          //les autres parametres sont avec double == seulement pour comparer la valeur
          //res.data.userdata.id_clt==3
          //res.data.userdata.nom_clt
          //res.data.userdata.prenom_clt
          //res.data.userdata.statut_clt
          
         
            if(res.data.success===true) 
            history.push("/profileSelf-page");
          else
            history.push("/conect-page");
          return;
        })
    } catch (error) { throw error; }
  };

  return (
    <div>
      <Header
        absolute
        color="white"
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>


          {/* <GridContainer>
            <GridItem xs={12} sm={12} md={6}>

            </GridItem>

            <GridItem xs={12} sm={12} md={4}>

            </GridItem>
          </GridContainer> */}

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form} onSubmit={submitUser}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Login</h4>
                  </CardHeader>

                  <CardBody signup>
                    <TextField
                      type="email"
                      id="_email"
                      name="email"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Email required"
                      placeholder="Enter email"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField
                      type="pass"
                      id="_pass"
                      name="pass"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Password required"
                      placeholder="Enter Password"
                      autoComplete="off"
                      required
                    />
                    <br /><br />

                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button type="submit" color="info" size="sm">Connexion</Button>
                  </div>
                </form>
                <br />
                <p className={classes.description + " " + classes.textCenter}>
                  <span className={classes.textLeft}>
                    <Link to="/">Forgot Password?</Link></span>
                  <span className={classes.textRight}>&nbsp;&nbsp;&nbsp;
                    <Link to="/account-page">Inscription</Link></span>
                </p>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      MYHOUSEOO
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://localhost/MyHouseOO/MENTIONS_LEGALES_MYHOUSEOO.pdf"
                      target="_blank"
                      className={classes.block}
                    >
                      LEGALE
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                      target="_blank"
                      className={classes.block}
                    >
                      CGU
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                Copyright
                &copy; {1900 + new Date().getYear()} , {" "}
                <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                >
                  ARTWEB-PRODUCTION
                </a>{" "}

              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
