import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import mapsStyle from "assets/jss/material-kit-pro-react/views/mapsSections/mapsStyle.js";


const useStyles = makeStyles(mapsStyle);

const handleClick = (e) => {
  console.log("latitude = ", e.latLng.lat());
  console.log("longtitude = ", e.latLng.lng());
  // const lat = e.latLng.lat();
  // const lng = e.latLng.lng();
};

export default function SectionContacts({ ...rest }) {

  const [checked, setChecked] = useState([]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();

  const [ismapdata, setMapData] = useState([]);

  const getMapData = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetMapData.php`)
        .then(res => {
          console.log(res.data.mapdata);
          setMapData(res.data.mapdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const RegularMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={{ lat: parseFloat(sessionStorage.getItem("latitude_map")), lng: parseFloat(sessionStorage.getItem("longitude_map")) - 0.025 }}
        //onClick={(e) => handleClick(e)}
        onClick={(e) => {
          console.log(ismapdata);
        }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true,
          styles: [
            {
              featureType: "water",
              stylers: [
                { saturation: 43 },
                { lightness: -11 },
                { hue: "#0088ff" }
              ]
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                { hue: "#ff0000" },
                { saturation: -100 },
                { lightness: 99 }
              ]
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#808080" }, { lightness: 54 }]
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.fill",
              stylers: [{ color: "#ece2d9" }]
            },
            {
              featureType: "poi.park",
              elementType: "geometry.fill",
              stylers: [{ color: "#ccdca1" }]
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#767676" }]
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#ffffff" }]
            },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            {
              featureType: "landscape.natural",
              elementType: "geometry.fill",
              stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
            },
            { featureType: "poi.park", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.sports_complex",
              stylers: [{ visibility: "on" }]
            },
            { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.business",
              stylers: [{ visibility: "simplified" }]
            }
          ]
        }}
      >
        {Object.keys(ismapdata).map((item) => (
          <Marker position={{ lat: parseFloat(ismapdata[item].latitude_map), lng: parseFloat(ismapdata[item].longitude_map) }} key={ismapdata[item].id_std}/>
        ))}
      </GoogleMap>
    ))
  );

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    getMapData();
  }, []);


  return (
    <div className="cd-section" {...rest}>
      <div className={classes.contenerMap}>
        <div className={classes.map}>
          <RegularMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDu94qM3uJh3t8IjHx6Uvksqb-D2sbJPaQ"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `100%`,
                  borderRadius: "6px",
                  overflow: "hidden"
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    </div>
  );
}
