/*eslint-disable*/
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Camera from "@material-ui/icons/Camera";

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import AccountCircle from "@material-ui/icons/AccountCircle";
import ArtTrack from "@material-ui/icons/ArtTrack";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";

import MapsPage from "views/MapsPage/Sections/SectionMapsPage.js";



import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import useLocalStorage from "hooks/useLocaleStorage.js";

import SectionSliderUnit from "views/PresentationPage/Sections/SectionSliderUnit.js";
import Accordion from "components/Accordion/Accordion.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Radio from "@material-ui/core/Radio";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";


import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

import Face from "@material-ui/icons/Face";

import CustomInput from "components/CustomInput/CustomInput.js";
import { TextField } from '@material-ui/core/';
import { TextareaAutosize } from '@material-ui/core/';

import { description } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
  });

  //const STORAGE_KEY = "storageSelectedEnabled";
  //const [storedSelectedEnabled,setStoredSelectedEnabled] = useLocalStorage(STORAGE_KEY,[]);

  const [selectedEnabled, setSelectedEnabled] = useState(1);

  //const [storedSelectedEnabled, setStoredSelectedEnabled] = useLocalStorage("storedSelectedEnabled", []);//tableau vide
  const [addmapInfo, setaddmapInfo] = useState({
    //whereParameter: '',
    // descriptionArea: '',
  });

  const [addcatalogueInfo, setaddcatalogueInfo] = useState({
    whereParameter: '',
    // descriptionArea: '',
  });
  const [addLocalisation, setaddLocalisationInfo] = useState({

  });
  const onChangeValue = (e) => {
    setaddcatalogueInfo({
      ...addcatalogueInfo,
      [e.target.name]: e.target.value
    });
  }
  const onChangeLocalization = (e) => {
    setaddLocalisationInfo({
      ...addLocalisation,
      [e.target.name]: e.target.value
    });
  }

  const handleChange = (e) => {
    console.log(e.descriptionArea.value);
  }

  const handleMultiple = event => {
    setMultipleSelect(event.target.value);
  };

  const [checked, setChecked] = React.useState([1, 2, 3]);

  const [checkedA, setCheckedA] = React.useState(true);

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const addToCatalogue = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      Axios.defaults.withCredentials = true;

      //whereParameter sessionStorage.getItem("city_map") ou un nouvel
      // console.log(sessionStorage.getItem("city_map"));//lancienne recherche
      console.log(addcatalogueInfo.whereParameter);
      console.log(addcatalogueInfo.descriptionArea);


      // //a pour villa , b pour house, c pour appartemnt stored
      console.log(selectedEnabled);
      console.log(sessionStorage.getItem("setPriceRangeUnit"));

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToCatalogue.php`, {

        propertyTitle: addcatalogueInfo.whereParameter,
        propertyType: selectedEnabled,
        propertyDescription: addcatalogueInfo.descriptionArea,
        propertyPrice: sessionStorage.getItem("setPriceRangeUnit"),
        propertyRoom: sessionStorage.getItem("setRoomRangeUnit"),
        propertyArea: sessionStorage.getItem("setSurfaceRangeUnit"),
        propertyCapacity: sessionStorage.getItem("setPeopleRangeUnit"),
      })
        .then(res => {
          // console.log(res.data.productdata['id_std']);
          sessionStorage.setItem("id_std", res.data.productdata['id_std']);
        })
    } catch (error) { throw error; }
  };

  const addToLocalization = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      console.log(addLocalisation.address);
      console.log(addLocalisation.postalcode);
      console.log(addLocalisation.city);
      console.log(addLocalisation.country);

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToLocalization.php`, {
        propertyAddress: addLocalisation.address,
        propertyPostalcode: addLocalisation.postalcode,
        propertyCity: addLocalisation.city,
        propertyCountry: addLocalisation.country,
        propertyId: sessionStorage.getItem("id_std"),
      })
    } catch (error) { throw error; }
  };


  return (
    <div>
      <Header
        color="black"
        brand="MyHouseOO"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "info"
        }}
        {...rest}
      />
      <Parallax
        image={require("assets/img/background-catalogue2.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br />
                <div className={classes.name}>
                  <h3 className={classes.title}>YOUR NAME HERE</h3>
                  <h6>PARAMETERS</h6>
                </div>
              </div>
              <div className={classes.follow}>
                <Tooltip
                  id="tooltip-top"
                  title="Add to Catalog"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="/contact-us"
                    justIcon
                    round
                    color="primary"
                    className={classes.followButton}
                  >
                    <Add className={classes.followIcon} />
                  </Button>
                </Tooltip>

              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter)}>
            <p>
              Update your profile, add an item to your catalog and view your entire catalog.{" "}
            </p>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Profil",
                  tabIcon: AccountCircle,
                  tabContent: (
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={7}
                        className={classes.gridItem}
                      >
                        <h4 className={classes.title}>Complete or update</h4>
                        <CustomInput
                          id="first"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "First Name...",
                            type: "text",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Face className={classes.inputIconsColor} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "Email...",
                            type: "email",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          id="pass"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "Password",
                            type: "password",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon className={classes.inputIconsColor}>
                                  lock_utline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off"
                          }}
                        />
                        <div className={classes.textCenter}>
                          <Button simple color="primary" size="lg">
                            UPDATE FORM
                          </Button>
                        </div>
                      </GridItem>

                    </GridContainer>
                  )
                },
                {
                  tabButton: "Add To Catalogue",
                  tabIcon: ArtTrack,
                  tabContent: (
                    <div>
                      <form className={classes.form} onSubmit={addToCatalogue}>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <Muted>
                              <h6>Your offer</h6>
                            </Muted>
                            <br />
                            <TextField className={classes.input}
                              type="text"
                              id="_whereParameter"
                              name="whereParameter"
                              variant="standard"
                              onChange={onChangeValue}
                              label="Title"
                              placeholder="Property's name..."
                              autoComplete="off"
                              required
                            />
                          </GridItem>
                        </GridContainer>
                        <br />   <br />
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.gridItem}
                          >
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              }
                            >

                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={selectedEnabled === 1}
                                    onChange={() => setSelectedEnabled(1)}
                                    value='1'
                                    name="radio button enabled"
                                    aria-label='1'
                                    icon={
                                      <FiberManualRecord className={classes.radioUnchecked} />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                      root: classes.radioRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Villa"
                              />
                            </div>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.gridItem}
                          >
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              }
                            >

                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={selectedEnabled === 2}
                                    onChange={() => setSelectedEnabled(2)}
                                    value='2'
                                    name="radio button enabled"
                                    aria-label='2'
                                    icon={
                                      <FiberManualRecord className={classes.radioUnchecked} />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                      root: classes.radioRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="House"
                              />
                            </div>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.gridItem}
                          >
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              }
                            >
                              <FormControlLabel

                                control={

                                  <Radio
                                    checked={selectedEnabled === 3}
                                    onChange={() => setSelectedEnabled(3)}
                                    value='3'
                                    name="radio button enabled"
                                    aria-label='3'
                                    icon={
                                      <FiberManualRecord className={classes.radioUnchecked} />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord className={classes.radioChecked} />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                      // disabled: classes.disabledCheckboxAndRadio,
                                      root: classes.radioRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Apartment"
                              />

                            </div>
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}>
                            <div className={classes.textCenter}>
                              <br /> <br />
                              <TextareaAutosize className={classes.textAreaWithStyle}
                                type="text"
                                id="_descriptionArea"
                                name="descriptionArea"
                                variant="standard"
                                onChange={onChangeValue}
                                label="Description"
                                placeholder="Property's description..."
                                rows={3}
                                autoComplete="off"
                                required
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                        <br /> <br />
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >
                            <SectionSliderUnit />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >
                            <div className={classes.textCenter}>
                              <Button color="info" round onClick={addToCatalogue}>SAVE FORM
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={10}
                          className={classes.gridItem}
                        >
                          <Muted>
                            <h6>Pictures</h6>
                          </Muted>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='1'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='2'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='3'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='4'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='5'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='6'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='7'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='8'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                      <br /><br />
                      <form className={classes.form} onSubmit={addToLocalization}>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <Muted>
                              <h6>LOCALIZATION</h6>
                            </Muted>
                            <br />
                            <TextField className={classes.input}
                              type="text"
                              id="_address"
                              name="address"
                              variant="standard"
                              onChange={onChangeLocalization}
                              label="Address"
                              placeholder="Property's adress..."
                              autoComplete="off"
                              required
                            />
                            <br /><br />
                            <TextField className={classes.input}
                              type="text"
                              id="_postalcode"
                              name="postalcode"
                              variant="standard"
                              onChange={onChangeLocalization}
                              label="Postal code"
                              placeholder="Postal code..."
                              autoComplete="off"
                              required
                            />
                            <br /><br />
                            <TextField className={classes.input}
                              type="text"
                              id="_city"
                              name="city"
                              variant="standard"
                              onChange={onChangeLocalization}
                              label="City"
                              placeholder="City..."
                              autoComplete="off"
                              required
                            />
                            <br /><br />
                            <TextField className={classes.input}
                              type="text"
                              id="_country"
                              name="country"
                              variant="standard"
                              onChange={onChangeLocalization}
                              label="Country"
                              placeholder="Country..."
                              autoComplete="off"
                              required
                            />
                          </GridItem>
                        </GridContainer>
                        <br /><br />
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <Muted>
                              <h6>GPS COORDINATES</h6>
                            </Muted>
                            <br />
                            <CustomInput
                              id="latitude"
                              inputProps={{
                                placeholder: "Latitude..."
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <CustomInput
                              id="longitude"
                              inputProps={{
                                placeholder: "Longitude..."
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >
                            <div className={classes.textCenter}>
                              <Button color="info" round onClick={addToLocalization}>SAVE LOCALISATION
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <br /><br />

                      <div className={classes.bigMap}>
                        <MapsPage />
                      </div>
                    </div>
                  )
                },


                {
                  tabButton: "View Catalogue",
                  tabIcon: Camera,

                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.textCenter}>
                        <Link to="/catalogue-page">
                        <Button round color="info">
                          Load your Catalogue
                          </Button>
                        </Link>
                          {/* <Button href="/catalogue-page" simple color="info" size="lg">
                            Load your Catalogue
                          </Button> */}
                        </div>
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    MYHOUSEOO
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="http://localhost/MyHouseOO/MENTIONS_LEGALES_MYHOUSEOO.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    LEGALE
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              Copyright
              &copy; {1900 + new Date().getYear()} , {" "}
              <a
                href="https://www.artwebprod.com"
                target="_blank"
              >
                ARTWEB-PRODUCTION
              </a>{" "}

            </div>
          </div>
        }
      />

    </div>
  );
}
