import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import styles from "assets/jss/material-kit-pro-react/views/presentationSections/sliderStyle.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {
  const [priceRange, setPriceRange] = React.useState([50, 10000]);
  const [surfaceRange, setSurfaceRange] = React.useState([15, 5000]);
  const [roomRange, setRoomRange] = React.useState([1, 10]);

  React.useEffect(() => {
    if (
      !document
        .getElementById("sliderRegular")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular"), {
        start: priceRange,
        connect: true,
        range: { min: 30, max: 12000 },
        step: 1
      }).on("change", function (values) {
        setPriceRange([Math.round(values[0]), Math.round(values[1])]);
        sessionStorage.setItem("setPriceRangeStart",Math.round(values[0]));
        sessionStorage.setItem("setPriceRangeEnd",Math.round(values[1]));
        // console.log(Math.round(values[0]));
        // console.log(Math.round(values[1]));
      });
    }
    if (
      !document
        .getElementById("sliderRegular2")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular2"), {
        start: surfaceRange,
        connect: true,
        range: { min: 10, max: 6000 },
        step: 1
      }).on("change", function (values) {
        setSurfaceRange([Math.round(values[0]), Math.round(values[1])]);
        sessionStorage.setItem("setSurfaceRangeStart",Math.round(values[0]));
        sessionStorage.setItem("setSurfaceRangeEnd",Math.round(values[1]));
        // console.log(Math.round(values[0]));
        // console.log(Math.round(values[1]));
      });
    }
    if (
      !document
        .getElementById("sliderRegular3")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular3"), {
        start: roomRange,
        connect: true,
        range: { min: 1, max: 15 },
        step: 1
      }).on("change", function (values) {
        setRoomRange([Math.round(values[0]), Math.round(values[1])]);
        sessionStorage.setItem("setRoomRangeStart",Math.round(values[0]));
        sessionStorage.setItem("setRoomRangeEnd",Math.round(values[1]));
        // console.log(Math.round(values[0]));
        // console.log(Math.round(values[1]));
      });
    }
    return function cleanup() { };
  });
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>            
              <CardBody className={classes.cardBodyRefine_}>
                <Info>
                  <h6>Price $</h6>
                </Info>
                <br />
                <span
                  className={classNames(
                    classes.pullLeft,
                    classes.priceSlider
                  )}
                >
                  {priceRange[0]}
                </span>
                <span
                  className={classNames(
                    classes.pullRight,
                    classes.priceSlider
                  )}
                >
                  {priceRange[1]}
                </span>
                <br />
                <br />
                <div id="sliderRegular" className="slider-info" />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card>            
              <CardBody className={classes.cardBodyRefine_}>
                <Info>
                  <h6>Surface m2</h6>
                </Info>
                <br />
              <span
                className={classNames(
                  classes.pullLeft,
                  classes.priceSlider
                )}
              >
                {surfaceRange[0]}
              </span>
              <span
                className={classNames(
                  classes.pullRight,
                  classes.priceSlider
                )}
              >
                {surfaceRange[1]}
              </span>
              <br />
              <br />
              <div id="sliderRegular2" className="slider-info" />
            </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card>            
              <CardBody className={classes.cardBodyRefine_}>
                <Info>
                  <h6>Number of Room</h6>
                </Info>
                <br />
              <span
                className={classNames(
                  classes.pullLeft,
                  classes.priceSlider
                )}
              >
                {roomRange[0]}
              </span>
              <span
                className={classNames(
                  classes.pullRight,
                  classes.priceSlider
                )}
              >
                {roomRange[1]}
              </span>
              <br />
              <br />
              <div id="sliderRegular3" className="slider-info" />
            </CardBody>
           </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
