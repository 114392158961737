/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
//import Footer from "components/Footer/Footer.js";
// sections for this page

import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCatalogueOffers from "views/CataloguePage/Sections/SectionCatalogueOffers.js";
import SectionProducts from "views/CataloguePage/Sections/SectionProducts.js";
import Footer from "views/FooterPage/FooterPage.js";

// import SectionBlog from "views/EcommercePage/Sections/SectionBlog.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
// import Mail from "@material-ui/icons/Mail";

// import ecommerceHeader from "assets/img/examples/ecommerce-header.jpg";


import styles from "assets/jss/material-kit-pro-react/views/catalogueStyle.js";

const useStyles = makeStyles(styles);

export default function EcommercePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/background-catalogue2.jpg")}
        filter="dark"
        small
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised_)}>
        {/* Catalogue standard */}
        <SectionCatalogueOffers />
        {/* Filtres pour Catalogue  */}
        {/* <SectionProducts /> */}
        <Footer />
      </div>     
    </div>
  );
}
