import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Accordion from "components/Accordion/Accordion.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import styles from "assets/jss/material-kit-pro-react/views/presentationSections/sliderStyle.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {
  const [priceRange, setPriceRange] = React.useState([50]);
  const [surfaceRange, setSurfaceRange] = React.useState([15]);
  const [roomRange, setRoomRange] = React.useState([1]);
  const [peopleRange, setPeopleRange] = React.useState([2]);



  React.useEffect(() => {
     if (
      !document
        .getElementById("sliderRegular")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular"), {
        start: priceRange,
        connect: [true, false],
        range: { min: 30, max: 12000 },
        step: 1
      }).on("change", function(values) {
        setPriceRange([Math.round(values[0])]);
        sessionStorage.setItem("setPriceRangeUnit",Math.round(values[0]));
      });
    }
    if (
      !document
        .getElementById("sliderRegular2")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular2"), {
        start: surfaceRange,
        connect: [true, false],
        range: { min: 10, max: 6000 },
        step: 1
      }).on("change", function(values) {
        setSurfaceRange([Math.round(values[0])]);
        sessionStorage.setItem("setSurfaceRangeUnit",Math.round(values[0]));
      });
    }
    if (
      !document
        .getElementById("sliderRegular3")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular3"), {
        start: roomRange,
        connect: [true, false],
        range: { min: 1, max: 15 },
        step: 1
      }).on("change", function(values) {
        setRoomRange([Math.round(values[0])]);
        sessionStorage.setItem("setRoomRangeUnit",Math.round(values[0]));

      });
    }
    if (
      !document
        .getElementById("sliderRegular4")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular4"), {
        start: roomRange,
        connect: [true, false],
        range: { min: 1, max: 10 },
        step: 1
      }).on("change", function(values) {
        setPeopleRange([Math.round(values[0])]);
        sessionStorage.setItem("setPeopleRangeUnit",Math.round(values[0]));

      });
    }
    return function cleanup() {};
  });
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}> 
      
                        <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                        >
                <Accordion
                  active={[0, 2]}
                  activeColor="info"
                  collapses={[
                    {
                      title: "Price $",
                      content: (
                        <CardBody className={classes.cardBodyRefine}>
                          <span
                            className={classNames(
                              classes.pullLeft,
                              classes.priceSlider
                            )}
                          >
                            {priceRange[0]}
                          </span>
                          {/* <span
                            className={classNames(
                              classes.pullRight,
                              classes.priceSlider
                            )}
                          >
                            {priceRange[1]}
                          </span> */}
                          <br />
                          <br />
                          <div id="sliderRegular" className="slider-info" />
                        </CardBody>
                      )
                    }
                  ]}
                />
                </GridItem>
                        
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                        >
                 <Accordion
                  active={[0, 2]}
                  activeColor="info"
                  collapses={[
                    {
                      title: "Surface m2",
                      content: (
                        <CardBody className={classes.cardBodyRefine}>
                          <span
                            className={classNames(
                              classes.pullLeft,
                              classes.priceSlider
                            )}
                          >
                            {surfaceRange[0]}
                          </span>
                          {/* <span
                            className={classNames(
                              classes.pullRight,
                              classes.priceSlider
                            )}
                          >
                            {surfaceRange[1]}
                          </span> */}
                          <br />
                          <br />
                          <div id="sliderRegular2" className="slider-info" />
                        </CardBody>
                      )
                    }
                  ]}
                />
                </GridItem>
                        </GridContainer>
                <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                        >
                 <Accordion
                  active={[0, 2]}
                  activeColor="info"
                  collapses={[
                    {
                      title: "Number of Room",
                      content: (
                        <CardBody className={classes.cardBodyRefine}>
                          <span
                            className={classNames(
                              classes.pullLeft,
                              classes.priceSlider
                            )}
                          >
                            {roomRange[0]}
                          </span>
                          {/* <span
                            className={classNames(
                              classes.pullRight,
                              classes.priceSlider
                            )}
                          >
                            {roomRange[1]}
                          </span> */}
                          <br />
                          <br />
                          <div id="sliderRegular3" className="slider-info" />
                        </CardBody>
                      )
                    }
                  ]}
                />
                </GridItem>
                        
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                        >
                 <Accordion
                  active={[0, 2]}
                  activeColor="info"
                  collapses={[
                    {
                      title: "Number of People",
                      content: (
                        <CardBody className={classes.cardBodyRefine}>
                          <span
                            className={classNames(
                              classes.pullLeft,
                              classes.priceSlider
                            )}
                          >
                            {peopleRange[0]}
                          </span>
                          {/* <span
                            className={classNames(
                              classes.pullRight,
                              classes.priceSlider
                            )}
                          >
                            {peopleRange[1]}
                          </span> */}
                          <br />
                          <br />
                          <div id="sliderRegular4" className="slider-info" />
                        </CardBody>
                      )
                    }
                  ]}
                />
                </GridItem>
                        </GridContainer>
      </div>
    </div>
  );
}
