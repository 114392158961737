/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";

import Parallax from "components/Parallax/Parallax.js";

import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";


const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  
  return (
    <div>
      <Header
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="dark" />}
        fixed
        color="transparent"
      />
      <Parallax
        image={require("assets/img/PHOTO-BANGKOK.jpg")}
        filter="transparent" small
        className={classes.parallax}
      />
    <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
     
          <div className={classes.container}>
            <h2 className={classes.title}>Send us a message</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  You can contact us with anything related to our Products. We
                  {"'"}ll get in touch with you as soon as possible.
                  <br />
                  <br />
                </p>
                <form>
                  <CustomInput
                    labelText="Your Name"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Email address"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Phone"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Your message"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6
                    }}
                  />
                  <div className={classes.textCenter}>
                    <Button color="info" round>
                      Contact us
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Find us at the office"
                  description={
                    <p>
                      10 Parc Club du Millénaire, <br /> 34000 Montpellier,{" "}
                      <br /> France
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="info"
                />
                <InfoArea
                  className={classes.info}
                  title="Give us a ring"
                  description={
                    <p>
                      MyHousseOO <br /> +33 6 34 45 93 47 <br /> Mon - Sat,
                      9:00 am - 5:00 pm 
                    </p>
                  }
                  icon={Phone}
                  iconColor="info"
                />
                <InfoArea
                  className={classes.info}
                  title="Legal Information"
                  description={
                    <p>
                      ARTWEB-PRODUCTION. <br /> RCS · 494.256.100  <br /> Montpellier ·
                      FRANCE
                    </p>
                  }
                  icon={BusinessCenter}
                  iconColor="info"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    
      <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      MYHOUSEOO     
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://localhost/MyHouseOO/MENTIONS_LEGALES_MYHOUSEOO.pdf"
                      target="_blank"
                      className={classes.block}
                    >
                      LEGALE
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                      target="_blank"
                      className={classes.block}
                    >
                      CGU
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                Copyright 
                &copy; {1900 + new Date().getYear()} , {" "}
                <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                >
                  ARTWEB-PRODUCTION
                </a>{" "}
               
              </div>
            </div>
          }
        />
    </div>
  );
}
