import {
  section,
  container,
  cardTitle,
  whiteColor,
  grayColor,
  dangerColor,
  mlAuto,
  hexToRgb,
  main,
  mainRaised,
  title,
  roseColor
} from "assets/jss/material-kit-pro-react.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const styles = {
  // cardTitle,
  main,
  ...imagesStyles,
  ...customSelectStyle,
  ...tooltipsStyle,
  container: {
    ...container,
    zIndex: 2
  },
  section: {
    ...section,
    padding: "70px 0px"
  },
  mainRaised: {
    ...mainRaised
  },
  title: {
    ...title,
    marginBottom: 0
  },
  sectionGray: {
    // background: grayColor[16]
  },
  mainPrice: {
    margin: "10px 0px 25px"
  },
  cardDescription: {
    color: grayColor[0],
    textAlign: "center"
  },
  mlAuto,
  priceContainer: {
    display: "inline-flex"
  },
  price: {
    fontSize: "18px",
    color: grayColor[22]
  },
  priceOld: {
    fontSize: "16px",
    textDecoration: "line-through"
  },
  priceNew: {
    color: dangerColor[0]
  },
  stats: {
    color: grayColor[0]
  },
  textCenter: {
    textAlign: "center!important"
  },
  textJustify: {
    textAlign: "justify"
  },
  features: {
    paddingTop: "30px"
  },
  productPage: {
    backgroundColor: whiteColor,
    "& $mainRaised": {
      margin: "-40vh 0 0",
      padding: "40px"
    },
    "& .image-gallery-slide img": {
      borderRadius: "10px",
      maxWidth: "450px",
      height: "auto"
    },
    "& .image-gallery-swipe": {
      margin: "42px 0px",
      overflow: "hidden",
      width: "100%",
      height: "auto",
      textAlign: "center"
    },
    "& .image-gallery-thumbnails > .image-gallery-thumbnails-container .image-gallery-thumbnail": {
      "&.active > .image-gallery-thumbnail-inner": {
        opacity: "1",
        borderColor: grayColor[6]
      },
      "& > .image-gallery-thumbnail-inner": {
        width: "80%",
        maxWidth: "85px",
        margin: "0 auto",
        padding: "8px",
        display: "block",
        border: "1px solid transparent",
        background: "transparent",
        borderRadius: "3px",
        opacity: ".8"
      },
      "& > .image-gallery-thumbnail-inner img": {
        borderRadius: "3px",
        width: "100%",
        height: "auto",
        textAlign: "center"
      }
    }
  },
  titleRow: {
    marginTop: "-8vh"
  },
  floatRight: {
    float: "right!important"
  },
  pickSize: {
    marginTop: "50px"
  },
  pullRight: {
    float: "right"
  },
  cardCategory: {
    textAlign: "center",
    marginTop: "10px"
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  // cardDescription: {
  //   textAlign: "center",
  //   color: grayColor[0]
  // },
  textRose: {
    color: roseColor[0]
  },
  justifyContentBetween: {
    justifyContent: "space-between!important"
  },
  img: {
    width: "20%",
    marginRight: "5%",
    marginBottom: "5%",
    float: "left"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    top: "3px",
    width: "18px",
    height: "18px",
    position: "relative"
  },
  shadowForPicture: {
    borderRadius: "10px",
    boxShadow: "0 3px 5px rgba(" + hexToRgb(grayColor[9]) + ",0.3)"
  }
};

export default styles;
