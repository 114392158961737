/*eslint-disable*/
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Search from "@material-ui/icons/Search";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

import { TextField } from '@material-ui/core/';

import InputAdornment from "@material-ui/core/InputAdornment";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';

import SectionSliderInLine from "views/PresentationPage/Sections/SectionSliderInLine.js";

import BackgroundPicture from "assets/img/background-catalogue2.jpg";

import MapsPage from "views/MapsPage/Sections/SectionMapsPage.js";
import CalendarPage from "views/CalendarPage/CalendarPage.js";
//import CalendarPage from "views/CalendarPage/CalendarPickDatePage.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/searchMapsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle";


const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {

  const [checked, setChecked] = React.useState([1, 2, 3]);
  console.log(checked); //pour recuperer la valeur ou +sieurs valeur

  const [checkedA, setCheckedA] = React.useState(true);
  //console.log(checkedA); pour recuperer la valeur true ou false

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  //const history = useHistory();

  const classes = useStyles();

  const [searchmapInfo, setsearchmapInfo] = useState({
    whereParameter: '',
    // email: '',
    // pass: '',
  });

  const onChangeValue = (e) => {
    setsearchmapInfo({
      ...searchmapInfo,
      [e.target.name]: e.target.value
    });
  }

  const getResearch = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      Axios.defaults.withCredentials = true;

      //whereParameter sessionStorage.getItem("city_map") ou un nouvel
      console.log(sessionStorage.getItem("city_map"));//lancienne recherche
      console.log(searchmapInfo.whereParameter);//la nouvelle

      //calendar `${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`
      console.log(sessionStorage.getItem("startDate"));
      console.log(sessionStorage.getItem("endDate"));

      //setPriceRange Math.round(values[0]), Math.round(values[1])
      console.log(sessionStorage.getItem("setPriceRangeStart"));
      console.log(sessionStorage.getItem("setPriceRangeEnd"));

      //setSurfaceRange Math.round(values[0]), Math.round(values[1]
      console.log(sessionStorage.getItem("setSurfaceRangeStart"));
      console.log(sessionStorage.getItem("setSurfaceRangeEnd"));

      //setRoomRange [Math.round(values[0] , Math.round(values[1];
      console.log(sessionStorage.getItem("setRoomRangeStart"));
      console.log(sessionStorage.getItem("setRoomRangeEnd"));

      //1 2 3
      console.log(checked);
      //true ou false
      console.log(checkedA);

      Axios.post(`${ApiConfig.baseUrl}/Controls/GetResearch.php`, {

        // username: userInfo.name,
        // useremail: userInfo.email,
        // userpass: userInfo.pass,
      })
        .then(res => {
          console.log(res.data);
          //console.log(res.data.success);

          //console.log(res.data.insertid);

          // if(res.data.success===true)
          //   history.push("/searchMaps-page");
          //   else
          //   history.push("/");
          //   return;
        })
    } catch (error) { throw error; }
  };

  return (
    <div>
      <Header
        brand="MyHouseOO"
        links={<HeaderLinks dropdownHoverColor="dark" />}
        fixed
        color="white"
      />

      <div className={classes.pageHeader}
        style={{
          backgroundImage: `url(${BackgroundPicture})`,
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.bigMap}>
          <MapsPage />
        </div>
        <GridContainer className={classes.textCenter}>
          <GridItem xs={12} sm={12} md={12}>
            <CalendarPage />
          </GridItem>
        </GridContainer>
        <div id="FormSearch" className={classes.container}>
          <br />

          <form className={classes.form} onSubmit={getResearch}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody className={classes.textCenter}>
                    <TextField className={classes.input}
                      type="text"
                      id="_whereParameter"
                      name="whereParameter"
                      variant="standard"
                      onChange={onChangeValue}
                      label={sessionStorage.getItem("city_map")}
                      placeholder="Where..."
                      autoComplete="off"
                      required
                    />

                    {/* <CustomInput
                    // labelText="Where..."
                    id="_whereParameter"
                    name = "whereParameter"
                    value ="testdelocalite"
                    onChange={onChangeValue}

                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder: "enter a place...",
                      placeholder: sessionStorage.getItem("city_map"),
                      type: "text",

                      startAdornment: (
                        <InputAdornment position="start">
                          <EditIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <RoomIcon />
                        </InputAdornment>
                      )
                    }}
                  /> */}
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <SectionSliderInLine />
              </GridItem>


              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardBody className={classes.textCenter_}>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(1)}
                            checked={checked.indexOf(1) !== -1 ? true : false}

                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label, root: classes.labelRoot }}
                        label="Villa"
                      />
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(2)}
                            checked={checked.indexOf(2) !== -1 ? true : false}

                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label, root: classes.labelRoot }}
                        label="House"
                      />
                    </div>
                    <div
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(3)}
                            checked={checked.indexOf(3) !== -1 ? true : false}

                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label, root: classes.labelRoot }}
                        label="Appartment"
                      />
                    </div>

                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardBody className={classes.textCenter}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedA}
                          onChange={event => setCheckedA(event.target.checked)}
                          value="checkedA"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Furnished / Unfurnished"
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <br />
                <div className={classes.textCenter}>
                  <Button color="info" round onClick={getResearch}>Search&nbsp;&nbsp;
                    <Search className={classes.icons} />
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </form>




        </div>
      </div>

      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    MYHOUSEOO
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="http://localhost/MyHouseOO/MENTIONS_LEGALES_MYHOUSEOO.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    LEGALE
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              Copyright
              &copy; {1900 + new Date().getYear()} , {" "}
              <a
                href="https://www.artwebprod.com"
                target="_blank"
              >
                ARTWEB-PRODUCTION
              </a>{" "}

            </div>
          </div>
        }
      />
    </div>
  );
}
