import React, { useState } from "react";
// nodejs library that concatenates classes
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
//import Favorite from "@material-ui/icons/Favorite";
import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import Email from "@material-ui/icons/Email";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
//import ItemPage from "views/ItemPage/ItemPage.js";

// import gucci from "assets/img/examples/gucci.jpg";
// import tomFord from "assets/img/examples/tom-ford.jpg";
// import dolce from "assets/img/examples/dolce.jpg";

//ITEM PAGE
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//import ShoppingCart from "@material-ui/icons/ShoppingCart";

// core components
import Accordion from "components/Accordion/Accordion.js";
// import itemStyle from "assets/jss/material-kit-pro-react/views/itemStyle.js";

// images
// import product1 from "assets/img/examples/product1.jpg";
// import product2 from "assets/img/examples/product2.jpg";
// import product3 from "assets/img/examples/product3.jpg";
// import product4 from "assets/img/examples/product4.jpg";



const useStyles = makeStyles(styles);

export default function SectionLatestOffers() {
  const classes = useStyles();

  const [iscatalogue, setcatalogue] = useState([]);

  const [isitem, setitem] = useState([]);

  //const [isPicture, setPicture] = useState([]);
  const [images, showPicture] = useState([]);

  const getCatalogueData = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      //Axios.get(`http://localhost/Controls/GetCatalogue.php`)
      //Axios.get(`http://localhost:80/Controls/GetCatalogue.php`)
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`)
        .then(res => {
          // console.log(res.data.getCatalogue.cataloguedata);
          // setcatalogue(res.data.getCatalogue.cataloguedata);
          console.log(res.data.cataloguedata);
          setcatalogue(res.data.cataloguedata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getItemData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      //Axios.post(`http://localhost/Controls/GetItem.php`, {
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetItem.php`, {
        id_std: id,
      })
        .then(res => {
          // console.log(res.data.getItem.itemdata);
          // setitem(res.data.getItem.itemdata);
          console.log(res.data.itemdata);
          setitem(res.data.itemdata);
          getPicturesData(id);
          smoothScrollDown("showItemId");
          return;
        })
    } catch (error) { throw error; }
  };


  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      //Axios.post(`http://localhost/Controls/GetPictures.php`, {
        Axios.post(`${ApiConfig.baseUrl}/Controls/GetPictures.php`, {
        id_std: id,
      })
        .then(res => {
          //console.log(res.data.getPictures.picturedata);
          console.log(res.data.picturedata);
          //setPicture(res.data.getPictures.picturedata);
          //showPicture(res.data.getPictures.picturedata);
          showPicture(res.data.picturedata);
          return;
        })
    } catch (error) { throw error; }
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };


const smoothScrollDown = target => {
  // var targetScroll = document.getElementById(target);
  scrollGo(document.documentElement, 6000, 1250);
};

  const smoothScrollUp = target => {
    // var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, 200, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  //ITEM PAGE
  const [colorSelect, setColorSelect] = React.useState("0");
 // const [sizeSelect, setSizeSelect] = React.useState("0");
  // const classesItem = useStylesItem();

  

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    getCatalogueData();
  }, []);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2>Offers</h2>
        <div id="showCatalogueId"></div>
        {/* <Button color="primary" onClick={getItemData}>Appeler la liste</Button> */}
        <GridContainer>
          {iscatalogue.map((item) => (
            <GridItem xs={12} sm={6} md={4}>
              <div key={item.id_std}>
                <Card product plain>
                  <CardHeader image plain>
                    <div className={classes.shadowForPicture}
                      style={{
                       
                        backgroundImage:`url(${ApiConfig.baseUrl}/images/${item.image})`,

                        
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        opacity: "1"
                      }}
                    >
                      <a href="#showItemId" onClick={() => getItemData(item.id_std)}>                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                      </a>
                    </div>
                  </CardHeader>
                  <CardBody className={classes.textCenter} plain>
                    <h4 className={classes.cardTitle}>{item.libelle}</h4>
                    <p>Type: {item.famille}</p>
                    <div className={classes.mlAuto}>
                      <span className={classNames(classes.price, classes.priceNew)}>
                        {" "}
                        {item.unitht}
                      </span>
                    </div>
                  </CardBody>
                  <CardFooter plain>
                    <p className={classes.textJustify}>
                      {item.description}
                    </p>
                  </CardFooter>
                </Card>
              </div>
            </GridItem>
          ))}
        </GridContainer>
        <br /><br /><br /><br /><br /><br /><br /><br /><br />


        <div id="showItemId"></div>
        <div className={classes.productPage}>
          {isitem.map((item) => (
            <div className={classes.ItemPage}>
              {/* <div className={classes.container}>
              <GridContainer className={classes.titleRow}>
                <GridItem md={4} className={classes.mlAuto}>
                  <Button color="white" className={classes.floatRight}>
                    <ShoppingCart /> 0 items
                  </Button>
                </GridItem>
              </GridContainer>
            </div> */}

              <div className={classNames(classes.section, classes.sectionGray)}>
                <div className={classes.container}>
                  <div className={classNames(classes.main, classes.mainRaised_)}>
                    <GridContainer>
                      <GridItem md={6} sm={6}>
                        <ImageGallery
                          showFullscreenButton={false}
                          showPlayButton={false}
                          startIndex={3}
                          items={images}

                          showThumbnails={true}
                          renderLeftNav={(onClick, disabled) => {
                            return (
                              <button
                                className='image-gallery-left-nav'
                                disabled={disabled}
                                onClick={onClick}
                              />
                            );
                          }}
                          renderRightNav={(onClick, disabled) => {
                            return (
                              <button
                                className='image-gallery-right-nav'
                                disabled={disabled}
                                onClick={onClick}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem md={6} sm={6}>
                        <h2 className={classes.title}>{item.libelle}</h2>
                        <h3 className={classes.mainPrice}>{item.unitht}</h3>
                        <Accordion
                          active={0}
                          activeColor="info"
                          collapses={[
                            {
                              title: "Description",
                              content: (
                                <p> {item.description}
                                </p>
                              )
                            },
                            // {
                            //   title: "Propertie Information",
                            //   content: (
                            //     <p>
                            //       Type: {item.groupe} <br />
                            //       Number of Room: {item.room} <br />
                            //       Surface: {item.area} <br />
                            //       Number of Person: {item.capacity} <br />
                            //      </p>
                            //   )
                            // },
                            {
                              title: "Propertie Information",
                              content: (
                                <ul>
                                  <li>Type: {item.groupe}</li>
                                  <li>Number of Room: {item.room}</li>
                                  <li>Surface: {item.area}</li>
                                  <li>Number of Person: {item.capacity}</li>
                                </ul>
                              )
                            }
                          ]}
                        />
                        <GridContainer className={classes.pickSize}>
                          <GridItem md={12} sm={12}>
                            <label>Your Choice</label>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={colorSelect}
                                onChange={event => setColorSelect(event.target.value)}
                                inputProps={{
                                  name: "colorSelect",
                                  id: "color-select"
                                }}
                              >
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="0"
                                >
                                  To Buy
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="1"
                                >
                                  To Rent
                                </MenuItem>
                                {/* <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="2"
                                >
                                  White
                                </MenuItem> */}
                              </Select>
                            </FormControl>
                          </GridItem>
                          {/* <GridItem md={6} sm={6}>
                            <label>Select size</label>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={sizeSelect}
                                onChange={event => setSizeSelect(event.target.value)}
                                inputProps={{
                                  name: "sizeSelect",
                                  id: "size-select"
                                }}
                              >
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="0"
                                >
                                  Small
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="1"
                                >
                                  Medium
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="2"
                                >
                                  Large
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem> */}
                        </GridContainer>
                        <br /><br />
                        <GridContainer className={classes.pullRight}>
                          {/* <Button round color="rose">
                          Add to Cart &nbsp; <ShoppingCart />
                        </Button> */}
                        <Link to="/contact-page">
                        <Button round color="danger">
                            Contact us &nbsp; <Email />
                          </Button>
                        </Link>

                          <Button round color="info"
                            endIcon={<ArrowForwardIcon />}

                            onClick={e => smoothScrollUp(e, "showCatalogueId")}
                          >
                            Back to catalog
                          </Button>

                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
            </div>

          )
          )}
        </div>


        {/* <GridContainer>
          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                <a href="#pablo">
                  <img src={gucci} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${gucci})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Gucci</h4>
                <p className={classes.cardDescription}>
                  The structured shoulders and sleek detailing ensure a sharp
                  silhouette. Team it with a silk pocket square and leather
                  loafers.
                </p>
              </CardBody>
              <CardFooter plain>
                <div className={classes.priceContainer}>
                  <span className={classNames(classes.price, classes.priceOld)}>
                    {" "}
                    €1,430
                  </span>
                  <span className={classNames(classes.price, classes.priceNew)}>
                    {" "}
                    €743
                  </span>
                </div>
                <div className={classNames(classes.stats, classes.mlAuto)}>
                  <Tooltip
                    id="tooltip-top"
                    title="Saved to Wishlist"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button justIcon simple color="rose">
                      <Favorite />
                    </Button>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>


          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                <a href="#pablo">
                  <img src={dolce} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dolce})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Dolce & Gabbana</h4>
                <p className={classes.cardDescription}>
                  The structured shoulders and sleek detailing ensure a sharp
                  silhouette. Team it with a silk pocket square and leather
                  loafers.
                </p>
              </CardBody>
              <CardFooter plain>
                <div className={classes.priceContainer}>
                  <span className={classNames(classes.price, classes.priceOld)}>
                    {" "}
                    €1,430
                  </span>
                  <span className={classNames(classes.price, classes.priceNew)}>
                    {" "}
                    €743
                  </span>
                </div>
                <div className={classNames(classes.stats, classes.mlAuto)}>
                  <Tooltip
                    id="tooltip-top"
                    title="Saved to Wishlist"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button justIcon simple color="rose">
                      <Favorite />
                    </Button>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
            <Card product plain>
              <CardHeader image plain>
                <a href="#pablo">
                  <img src={tomFord} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${tomFord})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody className={classes.textCenter} plain>
                <h4 className={classes.cardTitle}>Dolce & Gabbana</h4>
                <p className={classes.cardDescription}>
                  The structured shoulders and sleek detailing ensure a sharp
                  silhouette. Team it with a silk pocket square and leather
                  loafers.
                </p>
              </CardBody>
              <CardFooter plain>
                <div className={classes.priceContainer}>
                  <span className={classNames(classes.price, classes.priceOld)}>
                    {" "}
                    €1,430
                  </span>
                  <span className={classNames(classes.price, classes.priceNew)}>
                    {" "}
                    €743
                  </span>
                </div>
                <div className={classNames(classes.stats, classes.mlAuto)}>
                  <Tooltip
                    id="tooltip-top"
                    title="Saved to Wishlist"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button justIcon simple color="rose">
                      <Favorite />
                    </Button>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer> */}
      </div>
    </div>
  );
}
